import {
  AwsIcon,
  BitbucketIcon,
  CssIcon,
  DockerIcon,
  GithubIcon,
  GradleIcon,
  HtmlIcon,
  JavaIcon,
  JavascriptIcon,
  JiraIcon,
  KotlinIcon,
  MaterialUiIcon,
  MavenIcon,
  MysqlIcon,
  NodejsIcon,
  ReactIcon,
  SpringIcon,
  TypescriptIcon,
} from "./";

export const icons = [
  { element: JavaIcon, label: "Java" },
  { element: MavenIcon, label: "Maven" },
  { element: SpringIcon, label: "Spring" },
  { element: KotlinIcon, label: "Kotlin" },
  { element: GradleIcon, label: "Gradle" },
  { element: MysqlIcon, label: "MySQL" },
  { element: AwsIcon, label: "AWS" },
  { element: BitbucketIcon, label: "Bitbucket" },
  { element: DockerIcon, label: "Docker" },
  { element: GithubIcon, label: "GitHub" },
  { element: CssIcon, label: "CSS" },
  { element: HtmlIcon, label: "HTML" },
  { element: JavascriptIcon, label: "JavaScript" },
  { element: TypescriptIcon, label: "TypeScript" },
  { element: NodejsIcon, label: "Node.js" },
  { element: ReactIcon, label: "React" },
  { element: MaterialUiIcon, label: "Material UI" },
  { element: JiraIcon, label: "Jira" },
];
